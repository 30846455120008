import BaseError from './baseError';

class ErrorPaymentRequired extends BaseError {
  constructor(message) {
    super(message);
    this.name = 'ErrorPaymentRequired';
    this.errors = [];
    Object.setPrototypeOf(this, new.target.prototype);
  }

}

export default ErrorPaymentRequired;