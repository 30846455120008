import { spreadsheet as spreadsheetCore } from '@industriousapps/excelkits-core';
export function getWorkbookSheets(workbook) {
  return workbook.getSheets();
} // Convert to selection to range
// NOTE: disregards sheet selection

export function selectionToRange(selection) {
  const [first, last] = spreadsheetCore.cellReferencesToAddress(selection);
  const range = {
    startRow: first.row,
    startColumn: first.col,
    endRow: (last || first).row,
    endColumn: (last || first).col
  };
  return range;
}