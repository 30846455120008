import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import cloneDeep from 'lodash/cloneDeep';
import { utils as utilsCore, spreadsheet as spreadsheetCore } from '@industriousapps/excelkits-core';
import templateEngine from '@/utils/templateEngine';
import { deleteNotTruthey } from '@/utils/objects';
import logger from '@/utils/logger';
const {
  addNonExistentRows,
  addNonExistentColumns,
  cellReferencesToAddressMatrix,
  singleCellReferenceToAddress,
  mapContentfulCells
} = spreadsheetCore;
const {
  mapMatrix
} = utilsCore;
const PREFIX = 'common: utils: spreadsheets: update:'; // Apply a map of formats over
// a matrix of spreadsheet data

export function format(data, formats) {
  const result = cloneDeep(data);
  Object.entries(formats).forEach(_ref => {
    let [cellRef, cellFmt] = _ref;
    const {
      row,
      col
    } = singleCellReferenceToAddress(cellRef);
    const value = (result[row] || [])[col];
    if (value === undefined || !cellFmt) return;

    try {
      result[row][col] = templateEngine(`{{value | ssf ${cellFmt}}}`, {
        value
      });
    } catch (err) {
      logger.error(`${PREFIX} format: "${cellFmt}" was not parsable at "${cellRef}" ${err.message}`); // TODO: add Sentry error log
      // NOTE: should fail and continue without formatting the cell
    }
  });
  return result;
}
// Apply a format to all
// applicable cells in selection
export function insertFormat(_ref2) {
  let {
    cellRefs,
    format,
    srcData,
    srcFormats
  } = _ref2;
  const formatUpdates = {}; // Only apply formats to cells
  // containing anything to format

  mapContentfulCells(cellRefs, srcData, function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    const [,, ref] = args;
    formatUpdates[ref] = format;
  });
  return _objectSpread(_objectSpread({}, srcFormats), formatUpdates);
}
// Remove a format from all the
// referenced cells in selection
export function removeFormat(_ref3) {
  let {
    cellRefs,
    srcFormats
  } = _ref3;

  const result = _objectSpread({}, srcFormats);

  const relativeRefs = cellReferencesToAddressMatrix(cellRefs); // Remove formatting from all referenced cells

  mapMatrix(relativeRefs, (rowIdx, colIdx) => {
    result[relativeRefs[rowIdx][colIdx].ref] = '';
  }); // Remove empty formats

  deleteNotTruthey(result);
  return result;
} // Create a spreadsheet error instance

export function createSpreadsheetError(code, cellRef, value) {
  // @ts-ignore
  const err = new Error(`Spreadsheet Error at ${cellRef} with formula: "${value}"`);
  err.cellRef = cellRef;
  err.code = code;
  err.cellValue = value;
  return err;
} // Write to the users' clipboard
// given a selection of cells and a
// a single spreadsheet of data
// NOTE: should be a complied spreadsheet

export const insertClipboardText = async (cellRefs, spreadsheet) => {
  const selectedData = [];
  const addressMatrix = cellReferencesToAddressMatrix(cellRefs);

  for (let i = 0; i < addressMatrix.length; i++) {
    const row = addressMatrix[i];
    addNonExistentRows(selectedData, i);

    for (let k = 0; k < row.length; k++) {
      const address = row[k];
      const value = (spreadsheet[address.row] || [])[address.col];
      addNonExistentColumns(selectedData, i, k);
      selectedData[i][k] = value !== undefined ? value : '';
    }
  }

  const payload = selectedData.map(row => row.join('\t')).join('\n');

  try {
    await navigator.clipboard.writeText(payload);
  } catch (err) {
    throw Error(`${PREFIX} insertClipboardText: unable or not permitted to write to Clipboard API: ${err}`);
  }
};
export default {
  format,
  insertFormat,
  removeFormat,
  createSpreadsheetError
};