const breakpoints = {
  mobile: {
    maxWidth: 599
  },
  tablet: {
    minWidth: 600,
    maxWidth: 989
  },
  largeTablet: {
    minWidth: 740,
    maxWidth: 989
  },
  desktop: {
    minWidth: 990
  }
};
export default breakpoints;
export const breakpointQueries = {
  isSmall: {
    maxWidth: breakpoints.mobile.maxWidth
  },
  isMedium: {
    minWidth: breakpoints.tablet.minWidth,
    maxWidth: breakpoints.tablet.maxWidth
  },
  isMediumLarge: {
    minWidth: breakpoints.largeTablet.minWidth,
    maxWidth: breakpoints.largeTablet.maxWidth
  },
  isLarge: {
    minWidth: breakpoints.desktop.minWidth
  }
};