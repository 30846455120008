import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import envConfig from '@/config/environment';
import cdnConfig from '@/config/cdn';
import fetchJson from '@/utils/fetchJson';
import { createWrappedError } from '@/utils/errors';
const PREFIX = 'services: cdn:'; // Get the absolute url to the widget configs'
// JSON, within Firebase Hosting Domain, which
// is cached, if unchanged

export function getCDNWidgetConfigUrl(widgetId) {
  let baseUrl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : envConfig.baseUrl;
  return `${baseUrl}/${cdnConfig.widgetConfigsPath}/${widgetId}.json`;
} // Fetch the widget config JSON,
// within Firebase Hosting Domain,
// which is cached if unchanged

export function getCDNWidgetConfig(widgetId) {
  let baseUrl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : envConfig.baseUrl;
  const url = getCDNWidgetConfigUrl(widgetId, baseUrl);
  return fetchJson(url).then(_ref => {
    let {
      body: widgetConfig
    } = _ref;

    const result = _objectSpread(_objectSpread({}, widgetConfig), {}, {
      id: widgetId
    });

    if (widgetConfig.reportingWidget) {
      result.reportingWidget = {
        id: widgetConfig.reportingWidget
      };
    }

    return result;
  }).catch(err => {
    throw createWrappedError(`${PREFIX} getCDNWidgetConfig: failed to download widget config JSON at: "${url}"`, err);
  });
} // Get the absolute url to the widget polling
// JSON, within Firebase Hosting Domain, which
// is cached, if unchanged

export function getCDNWidgetPollingUrl(widgetId) {
  let baseUrl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : envConfig.baseUrl;
  return `${baseUrl}/${cdnConfig.widgetPollingPath}/${widgetId}.json`;
} // Fetch the widget polling JSON,
// within Firebase Hosting Domain,
// which is cached if unchanged

export function getCDNWidgetPolling(widgetId) {
  let baseUrl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : envConfig.baseUrl;
  const url = getCDNWidgetPollingUrl(widgetId, baseUrl);
  return fetchJson(url).then(_ref2 => {
    let {
      body: result
    } = _ref2;
    return result;
  }).catch(err => {
    throw createWrappedError(`${PREFIX} getCDNWidgetPolling: failed to download widget polling JSON at: "${url}"`, err);
  });
} // Get the absolute url to the widget production
// workbook JSON, within Firebase Hosting Domain,
// which is cached, if unchanged

export function getCDNWidgetWorkbookUrl(widgetId) {
  let baseUrl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : envConfig.baseUrl;
  return `${baseUrl}/${cdnConfig.widgetWorkbookPath}/${widgetId}.json`;
}
export default {
  getCDNWidgetConfigUrl,
  getCDNWidgetConfig,
  getCDNWidgetPollingUrl,
  getCDNWidgetPolling
};