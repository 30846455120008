import { db } from '@/config/firebase';
import firebase from 'firebase/app';
import { useFirestoreCollectionData } from 'reactfire';
import collections from '@/config/collections';
const PREFIX = 'services: firestore: workbooks:';
const COLLECTION_NAME = collections.workbooks;
export default {
  // Create database document reference
  createDocRef(id) {
    return db.collection(COLLECTION_NAME).doc(id);
  },

  // Query for a list of records
  query(db, srcQuery) {
    if (Object.keys(srcQuery).length === 0) {
      throw Error('query: requires a query configuration with at least one query');
    }

    let query = db.collection(COLLECTION_NAME); // Append each query as where clause

    Object.keys(srcQuery).forEach(attr => {
      const queryArgs = srcQuery[attr];
      query = query.where(attr === 'id' ? firebase.firestore.FieldPath.documentId() : attr, queryArgs[0], queryArgs[1]);
    });
    const {
      status,
      error,
      data: srcData = [] // eslint-disable-next-line

    } = useFirestoreCollectionData(query, {
      idField: 'id'
    }); // Cast data into target type

    const data = srcData.map(itemData => itemData); // Result

    return {
      status,
      error,
      data
    };
  },

  // Lookup non-syncing snapshot
  // of a Firebase record
  findRecordSnapshot(dbRef, id) {
    return dbRef.collection(COLLECTION_NAME).doc(id).get().then(doc => doc.data()).catch(err => Promise.reject(Error(`${PREFIX} request failed: ${err}`)));
  }

};