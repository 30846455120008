import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import ErrorBadRequest from '@/interfaces/errors/badRequest';
import ErrorConflictingRequest from '@/interfaces/errors/conflictingRequest';
import ErrorUnauthorized from '@/interfaces/errors/unauthorized';
import ErrorPaymentRequired from '@/interfaces/errors/paymentRequired';
import ErrorForbidden from '@/interfaces/errors/forbidden';
import ErrorNotFound from '@/interfaces/errors/notFound';
import ErrorProxyForbidden from '@/interfaces/errors/proxyForbidden';
import ErrorServerInternal from '@/interfaces/errors/serverInternal';
export const DEFAULT_ERROR_MESSAGES = Object.freeze({
  400: 'fix request errors',
  401: 'user authorization not accepted',
  402: 'payment required for this request',
  403: 'user lacks permission',
  404: 'record not found',
  407: '3rd party service not authenticated',
  409: 'fix conflicting request errors',
  500: 'system failure'
});

const createError = function (prefix) {
  let customMessages = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  // Merge default errors with
  // any custom error messages
  const errorMessages = _objectSpread(_objectSpread({}, DEFAULT_ERROR_MESSAGES), customMessages);

  return function (responseStatus) {
    let responseErrors = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    const apiErrors = responseErrors && Array.isArray(responseErrors) ? responseErrors : []; // Bad user request

    if (responseStatus === 400) {
      const badRequest = new ErrorBadRequest(`${prefix} ${errorMessages[400]}`);
      badRequest.addErrors(apiErrors);
      return badRequest;
    } // Unauthenticated / Unauthorized


    if (responseStatus === 401) {
      const unauthorizedRequest = new ErrorUnauthorized(`${prefix} ${errorMessages[401]}`);
      unauthorizedRequest.addErrors(apiErrors);
      return unauthorizedRequest;
    } // Unpaid request


    if (responseStatus === 402) {
      const unpaidRequest = new ErrorPaymentRequired(`${prefix} ${errorMessages[402]}`);
      unpaidRequest.addErrors(apiErrors);
      return unpaidRequest;
    } // Unauthenticated / Forbidden


    if (responseStatus === 403) {
      const forbiddenRequest = new ErrorForbidden(`${prefix} ${errorMessages[403]}`);
      forbiddenRequest.addErrors(apiErrors);
      return forbiddenRequest;
    } // Record(s) not found


    if (responseStatus === 404) {
      const notFoundRequest = new ErrorNotFound(`${prefix} ${errorMessages[404]}`);
      notFoundRequest.addErrors(apiErrors);
      return notFoundRequest;
    } // Proxy service was unable to auth request


    if (responseStatus === 407) {
      const proxyForbiddenRequest = new ErrorProxyForbidden(`${prefix} ${errorMessages[407]}`);
      proxyForbiddenRequest.addErrors(apiErrors);
      return proxyForbiddenRequest;
    } // Database state conflicts with request


    if (responseStatus === 409) {
      const conflictingRequest = new ErrorConflictingRequest(`${prefix} ${errorMessages[409]}`);
      conflictingRequest.addErrors(apiErrors);
      return conflictingRequest;
    } // Unexpected internal error


    if (responseStatus === 500) {
      const serverInternalRequest = new ErrorServerInternal(`${prefix} ${errorMessages[500]}`);
      serverInternalRequest.addErrors(apiErrors);
      return serverInternalRequest;
    }

    return null;
  };
};

export default createError;