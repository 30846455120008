import eventsConfig from '@/config/globalEvents';
const PREFIX = 'common: utils: globalEvents:';
export default {
  // Publish a global event by name
  trigger(name, detail) {
    const [internalName, eventTarget] = name.split('.');
    const internalEvent = eventsConfig[internalName];

    if (!internalEvent) {
      throw Error(`${PREFIX} event named "${internalName}" is not configured`);
    }

    const event = new CustomEvent(`${internalEvent}${eventTarget ? '.' : ''}${eventTarget || ''}`, detail);

    if (true) {
      window.dispatchEvent(event);
    }
  },

  // Create a subscriber for a callback
  // returns an unsubscriber function
  subscribe(name, callback) {
    const [internalName, eventTarget] = name.split('.');
    const internalEvent = eventsConfig[internalName];

    if (!internalEvent) {
      throw Error(`${PREFIX} event named "${internalName}" is not a globally configured event`);
    }

    if (true) {
      const finalEventName = `${internalEvent}${eventTarget ? '.' : ''}${eventTarget || ''}`;
      window.addEventListener(finalEventName, callback);
      return () => window.removeEventListener(finalEventName, callback);
    } // eslint-disable-next-line


    return () => {}; // noop
  }

};