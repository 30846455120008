import { useFirestoreDocData } from 'reactfire';
import { db } from '@/config/firebase';
import collections from '@/config/collections';
const COLLECTION_NAME = collections.teams;
export const updateTeam = (id, data) => {
  return db.collection(COLLECTION_NAME).doc(id).update(data);
};
export const getTeam = teamId => {
  return db.collection(COLLECTION_NAME).doc(teamId).get().then(doc => doc.data());
};
export const getTeamName = teamId => {
  return db.collection(COLLECTION_NAME).doc(teamId).get().then(doc => {
    var _doc$data;

    return (_doc$data = doc.data()) === null || _doc$data === void 0 ? void 0 : _doc$data.name;
  });
}; /// Check if a team already has the given slug

export const isSlugAvailable = async slug => {
  const teams = await db.collection('teams').where('slug', '==', slug).get();
  return teams.empty;
};
export default {
  isSlugAvailable,
  getTeamName,
  getTeam,
  updateTeam,

  // Create database document reference
  createDocRef(id) {
    return db.collection(COLLECTION_NAME).doc(id);
  },

  // Lookup a single record
  findRecord(fsDb, id) {
    const docRef = fsDb.collection(COLLECTION_NAME).doc(id); // eslint-disable-next-line

    const {
      status,
      error,
      data: srcData // eslint-disable-next-line

    } = useFirestoreDocData(docRef, {
      idField: 'id'
    }); // Cast data into local record

    const data = srcData; // Result

    return {
      status,
      error,
      data
    };
  }

};