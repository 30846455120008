import storageConf from '@/config/storage';
import { isWorkbook } from '@/utils/spreadsheets';
import fetchJson from '@/utils/fetchJson';
import memory from '@/services/memory';
import { createWrappedError } from '@/utils/errors';
const PREFIX = 'common: services: workbooks:';
export default {
  // Download a Workbook JSON
  // file from a target URL
  downloadWorkbook(downloadUrl) {
    let cachable = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    let _fetch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : fetch;

    return memorizedFetch(downloadUrl, _ref => {
      let {
        body
      } = _ref;
      let srcData = body; // Prefer data field if present
      // otherwise assume entire body is
      // workbook payload

      if (body.data) {
        srcData = body.data;
      } // Accept a spreadsheet or a workbook file


      const data = isWorkbook(srcData) ? srcData : [srcData];
      return {
        data,
        digest: body.digest || ''
      };
    }, cachable, _fetch);
  },

  // Download a Workbook Meta JSON file from
  // a Firebase storage download URL
  downloadWorkbookMeta(downloadUrl) {
    let cachable = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    let _fetch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : fetch;

    return memorizedFetch(downloadUrl, _ref2 => {
      let {
        body: result
      } = _ref2;
      return result;
    }, cachable, _fetch);
  },

  // Get a download URL for a
  // any workbook related file
  // that's uploaded to storage
  async getDownloadUrl(storage, relativePath) {
    const filePath = prependWorkbookDir(relativePath);
    const storageRef = storage.ref(filePath);
    let downloadUrl = '';

    try {
      downloadUrl = await storageRef.getDownloadURL();
    } catch (err) {
      throw createWrappedError(`${PREFIX} getDownloadUrl: failed to download spreadsheet URL at: ${filePath}`, err);
    }

    return downloadUrl;
  },

  removeFromCache
}; // Purge entry from local memory cache

export function removeFromCache(requestUrl) {
  delete memory.spreadsheets[requestUrl];
} // Return active or cached request
// cleaning up based on caching rules

function memorizedFetch(requestUrl, successHandler) {
  let cachable = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  let _fetch = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : fetch;

  if (memory.spreadsheets[requestUrl]) {
    return memory.spreadsheets[requestUrl];
  } // Perform request


  const request = fetchJson(requestUrl, _fetch).then(successHandler).catch(err => {
    // Always allow retrying on failure
    delete memory.spreadsheets[requestUrl];
    return Promise.reject(err);
  }).finally(() => {
    if (!cachable) {
      // Allow subsequent requests
      removeFromCache(requestUrl);
    }
  }); // Store active request

  memory.spreadsheets[requestUrl] = request;
  return request;
} // Ensure that a file path contains
// the workbook dir prefix


function prependWorkbookDir(relativePath) {
  let filePath = relativePath; // Prepend workbook directory if not already present

  if (filePath.search(storageConf.workbookDir) < 0) {
    filePath = `${storageConf.workbookDir}/${relativePath}`;
  }

  return filePath;
}