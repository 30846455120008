import { breakpointQueries } from '@/config/breakpoints';
const PREFIX = 'common: utils: responsive:';
export function isSmall(width) {
  let queries = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : breakpointQueries;

  if (!queries || !queries.isSmall || typeof queries.isSmall.maxWidth !== 'number') {
    throw Error(`${PREFIX} isSmall: invalid query definition`);
  }

  return width <= queries.isSmall.maxWidth;
}
export function isMedium(width) {
  let queries = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : breakpointQueries;

  if (!queries || !queries.isMedium || typeof queries.isMedium.minWidth !== 'number' || typeof queries.isMedium.maxWidth !== 'number') {
    throw Error(`${PREFIX} isMedium: invalid query definition`);
  }

  return width >= queries.isMedium.minWidth && width <= queries.isMedium.maxWidth;
}
export function isLarge(width) {
  let queries = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : breakpointQueries;

  if (!queries || !queries.isLarge || typeof queries.isLarge.minWidth !== 'number') {
    throw Error(`${PREFIX} isLarge: invalid query definition`);
  }

  return width >= queries.isLarge.minWidth;
}
export function isMediumOrSmaller(width) {
  let queries = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : breakpointQueries;
  return isSmall(width, queries) || isMedium(width, queries);
}
export default {
  isSmall,
  isMedium,
  isLarge,
  isMediumOrSmaller
};