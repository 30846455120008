import generalConfig from '@/config/general';
let globalRegistry = {};
const sym = Symbol.for(generalConfig.productNameSpace);

if (true) {
  if (window[sym]) {
    globalRegistry = window[sym]; // use existing
  } else {
    window[sym] = globalRegistry; // add registry
  }
} // Add scopes


globalRegistry.spreadsheets = {};
export default globalRegistry;