import moment from 'moment'; // import formatConfig from '@/config/formats';

export const DUR_SECOND = 1 / 86400;
export const DUR_MINUTE = 1 / 1440;
export const DUR_HOUR = 1 / 24; // Answer may be propagated up

export function isValidDuration(val) {
  return val.search(/\d{2}:|\.\d{2}:|\.\d{2}/) === 0;
} // Convert a numeric duration (percentage of 24 hours)
// to a moment representation "hh:mm:ss"

export function durationNumberToMoment(val) {
  const mo = moment();
  const hours = Math.floor(val / DUR_HOUR);
  const hoursDiff = hours * DUR_HOUR;
  const minutes = Math.floor((val - hoursDiff) / DUR_MINUTE);
  const minDiff = minutes * DUR_MINUTE;
  const seconds = Math.round((val - hoursDiff - minDiff) / DUR_SECOND);
  mo.hour(hours);
  mo.minute(minutes);
  mo.seconds(seconds);
  return mo;
} // Turn a duration string "HH:mm:ss" into it's numberic
// equivalent in terms of a fraction of a day
// DEPRECATED replace with ssf

export function durationStringToNumber(duration) {
  const [hrs, mins, secs] = duration.split(/:|\./).map(function () {
    let seg = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '00';
    return parseFloat(seg);
  }); // parse

  return hrs * DUR_HOUR + mins * DUR_MINUTE + secs * DUR_SECOND;
} // Convert a numeric duration (percentage of 24 hours)
// to a string representation "hh:mm:ss"

export function durationNumberToString(val) {
  const totalSeconds = Math.round(Math.abs(val) / DUR_SECOND);
  const momentDuration = moment.duration(totalSeconds * 1000);
  return `${val < 0 ? '-' : ''}${moment.utc(momentDuration.as('milliseconds')).format('HH:mm:ss')}`;
}